import axios from 'axios';
import { server } from '../js/variables.js'
import moment from 'moment'

var requestMixin = {
  data: function () {
    return {
      request: {
        status: false,
        range: null,
      },
    }
  },
  methods: {
    listRequestsMixin(limit, page, search, order, sort, status, startDate, endDate, company, iddriver, idvehicle, vehicleType, registration_status, idRegistration) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        from: startDate,
        to: endDate,
        idcompany: company,
        iddriver: iddriver,
        idvehicle: idvehicle,
        vehicleType: vehicleType,
        registration_status: registration_status,
        idRegistration: idRegistration,
        onlyNotExpired: 0
      };

      return axios.get(server.api_url + '/requests', config)
        .then((response) => {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    listRequestsCompanionMixin(limit, page, search, order, sort, status, startDate, endDate, idcompanion) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        from: startDate,
        to: endDate,
        idcompanion: idcompanion,
        onlyNotExpired: 0
      };

      return axios.get(server.api_url + '/requests/companions', config)
        .then((response) => {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getNextRequestMixin() {
      const self = this;

      return axios.get(server.api_url + '/requests/next', server.config)
        .then((response) => {
          let responseData = response.data.data;

          //remover mascara
          if (responseData.request.Driver.phone && responseData.request.Driver.phone.length > 11) {
            responseData.request.Driver.phone = self.removeMaskPhoneDriver(responseData.request.Driver.phone);
          }

          if (responseData.request.Driver.company_cnpj && responseData.request.Driver.company_cnpj.length > 14) {
            let cnpj = responseData.request.Driver.company_cnpj.slice(0, 8);
            cnpj += responseData.request.Driver.company_cnpj.slice(-6);
            responseData.request.Driver.company_cnpj = cnpj;
          }

          return responseData;
        })
        .catch(function () {
          return null;
        });
    },
    getRequestMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/requests/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;

          //remover mascara
          if (responseData.request.Driver.phone && responseData.request.Driver.phone.length > 11) {
            responseData.request.Driver.phone = self.removeMaskPhoneDriver(responseData.request.Driver.phone);
          }

          if (responseData.request.Driver.company_cnpj && responseData.request.Driver.company_cnpj.length > 14) {
            let cnpj = responseData.request.Driver.company_cnpj.slice(0, 8);
            cnpj += responseData.request.Driver.company_cnpj.slice(-6);
            responseData.request.Driver.company_cnpj = cnpj;
          }

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getMultipleRequestMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/mult-requests/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;

          for (let request of responseData.package.Requests) {
            //remover mascara
            if (request.Driver.phone && request.Driver.phone.length > 11) {
              request.Driver.phone = self.removeMaskPhoneDriver(request.Driver.phone);
            }

            if (request.Driver.company_cnpj && request.Driver.company_cnpj.length > 14) {
              let cnpj = request.Driver.company_cnpj.slice(0, 8);
              cnpj += request.Driver.company_cnpj.slice(-6);
              request.Driver.company_cnpj = cnpj;
            }
          }

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    approveRequestMixin(request) {
      const self = this;
      this.$refs.validator.validate();
      if (request.hour_min.length == 5 && request.hour_max.length == 5) {
        if (this.$refs.validator.flags.valid) {
          let status = request.status;
          if (status != 2) {
            status = 2
          }
          let requestToSend = {
            hour_min: request.hour_min,
            hour_max: request.hour_max,
            status: status,
          }
          return axios.put(server.api_url + '/requests/' + request.idrequest, {
            request: requestToSend,
          }).then((response) => {
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
            });
        } else {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique se os campos estão corretos',
            confirmButtonText: 'OK'
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique o horário',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    denyRequestMixin(request) {
      const self = this;
      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        let status = request.status;
        if (status != -1) {
          status = -1
        }
        let requestToSend = {
          hour_min: request.hour_min,
          hour_max: request.hour_max,
          status: status,
        }
        this.axios.put(server.api_url + '/requests/' + request.idrequest, {
          request: requestToSend,
        }).then((response) => {
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          })
        })
          .catch(function (error) {
            self.showError(error);
          });
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    disableApproveDeny(request) {
      let day = request.day.split(' ')[0] + ' ' + request.hour_max
      let dayMin = request.day.split(' ')[0] + ' ' + request.hour_min
      day = moment(day)
      dayMin = moment(dayMin)
      let diffHourMax = moment().diff(day)
      let diffHourMin = day.diff(dayMin)
      // console.log('dayHourMax', day, diffHourMax)
      // console.log('diffHourMin', dayMin, diffHourMin)

      if (diffHourMax > 0) {
        if (diffHourMax < 86400000 && diffHourMin < 0) return false
        else return true
      }
      else return false
    },
    appRequestMultipleMixin(request) {
      const self = this
      let requestToSend = JSON.parse(JSON.stringify(request));
      return axios.post(server.api_url + '/mult-requests', {
        request: requestToSend.request,
      }).then((response) => {
        let responseData = response.data.data;
        return responseData
      })
        .catch(function (error) {
          self.showError(error);
          return null
        });
    },
    saveRequestMultipleMixin(request, param) {
      const self = this
      let requestToSend = JSON.parse(JSON.stringify(request));
      if (param === 'new') {
        this.axios.post(server.api_url + '/mult-requests', {
          request: requestToSend.request,
        }).then((response) => {
          // let responseData = response.data.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.open('/requests/' + response.data.data.package.idpackage, '_self');
            }
          })
        })
          .catch(function (error) {
            self.showError(error);
          });
      } else {
        return this.axios.put(server.api_url + '/mult-requests/' + param,
          requestToSend,
        ).then((response) => {
          let responseData = response.data.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // window.open('/requests', '_self');
            }
          })
          return responseData
        })
          .catch(function (error) {
            self.showError(error);
          });
      }
    },
    approveMultipleRequestMixin(idpackage) {
      const self = this
      return this.axios.put(server.api_url + '/mult-requests/' + idpackage + '/allow').then((response) => {
        let responseData = response.data.data;
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: response.data.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // window.open('/requests', '_self');
          }
        })
        return responseData
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    denyMultipleRequestMixin(idpackage) {
      const self = this
      return this.axios.put(server.api_url + '/mult-requests/' + idpackage + '/deny').then((response) => {
        let responseData = response.data.data;
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: response.data.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // window.open('/requests', '_self');
          }
        })
        return responseData
      })
        .catch(function (error) {
          self.showError(error);
        });
    },
    prepareCSVRequests(data) {
      let array = JSON.parse(JSON.stringify(data));
      let arrayReady = [];
      for (let item of array) {
        item.created_at = moment(String(item.created_at)).format('DD/MM/YYYY')
        item.Driver.created_at = moment(String(item.Driver.created_at)).format('DD/MM/YYYY')
        let primeiraParte = item.Driver.phone.slice(0, 2);
        let segundaParte = item.Driver.phone.slice(2, 7);
        let terceiraParte = item.Driver.phone.slice(-4);
        item.Driver.phone = '(' + primeiraParte + ') ' + segundaParte + '-' + terceiraParte;

        let objFormated = {
          code: item.idrequest,
          company_code: item.company_code,
          status: this.userStatus(item.status),
          day: item.day,
          hour: item.hour,
          hour_start: item.hour_min,
          hour_end: item.hour_max,
          //registration 
          registration_code: item.registration.idregistration,
          registration_status: this.userStatus(item.registration.status),
          registration_data: item.registration.created_at,
          registration_expiration: item.registration.expiration,

          // driver
          driver_name: item.Driver.name + item.Driver.lastname,
          driver_cpf: item.Driver.cpf,
          driver_cnh: item.Driver.cnh,
          driver_phone: item.Driver.phone,
          driver_email: item.Driver.email,
          driver_birthday: item.Driver.birthday,

          // user
          approver_name: item.User ? item.User.name + item.User.lastname : "",
          approver_phone: item.User ? item.User.phone : "",
          approver_email: item.User ? item.User.email : "",

          //vehicle
          vehicle_type: item.Vehicle?.type ? item.Vehicle.type : 'PEDESTRE',
          vehicle_plate: item.Vehicle?.plate ?? '',
          vehicle_year: item.Vehicle?.year ?? '',
          vehicle_color: item.Vehicle?.color ?? '',

          // driver_company
          driver_company_name: item.Driver.company_name,
          driver_company_cnpj: item.Driver.company_cnpj,

          // company
          company_name: item.Company.name,
          company_cnpj: item.Company.cnpj,
          company_phone: item.Company.phone,
          company_email: item.Company.email,

          // Park
          park_name: item.Park.name,
          park_lat: item.Park.lat,
          park_lng: item.Park.lng,
        };
        arrayReady.push(objFormated)
      }

      // console.log('arrayReady', arrayReady)
      return arrayReady;
    },
  }
};

export default requestMixin;