<template>
  <div class="data-item" :style="{ 'pointer-events': noOverlay ? 'none' : 'auto' }">
    <div class="row">
      <div class="col-md-8 data-middle">
        <ImageItem :path="requestItem.Company.logo" :isSmall="true"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">Empresa: {{ requestItem.Company.name | verifyIfNull }}</p>
          <p>Status: <span class="highlight" v-bind:class="userStatusClass(requestItem.status)">{{
            requestStatus(requestItem.status) }}</span></p>
          <p>Parque: {{ (requestItem.Park.name) }}</p>
        </div>
      </div>
      <div class="col-md-4 data-right">
        <p>Data alvo: {{ requestItem.day | formatDate }}</p>
        <p>Hora: {{ requestItem.hour }}</p>
        <p v-if="requestItem.Company.company_code_enable">{{ requestItem.Company.company_code_name || 'Número do cliente' }}: <span class="highlight">{{ requestItem.company_code | verifyIfNull }}</span></p>
      </div>
    </div>

    <overlay-item v-if="isDriver && !noOverlay" :route="`/visitante/solicitacao/${requestItem.idrequest}`"></overlay-item>
    <overlay-item v-if="!isDriver && !noOverlay" :route="requestItem.idpackage ? `/requests/${requestItem.idpackage}` : `/request-view/${requestItem.idrequest}`"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["requestItem", "isDriver", "noOverlay"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() { },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.request-item {}

@media only screen and (max-width: 600px) {
  .request-item {}
}
</style>