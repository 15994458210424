<template>
  <main-layout>
    <div class="content-container">
      <div id="request-register" class="content-box">
        <div class="row">
          <div class="col-md-4 separator-bottom">
            <h1 class="title-md">Solicitação</h1>
          </div>
          <div class="col-md-8 last">
            <button class="app-btn app-btn-outline separator-bottom" @click="goBackWindow()">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveRequest()"
              v-if="this.$route.fullPath.indexOf('/requests/new') === -1 && isThirdMember()">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveRequest()"
              v-if="this.$route.fullPath.indexOf('/requests/new') !== -1 && isThirdMember()">Registrar</button>
            <button class="app-btn app-btn-cancel left-space action" @click="denyAll()"
              v-if="this.$route.fullPath.indexOf('/requests/new') === -1 && (isThirdMember() || isCompanyMember())">Reprovar todos</button>
            <button class="app-btn app-btn-primary left-space action" @click="approveAll()"
              v-if="this.$route.fullPath.indexOf('/requests/new') === -1 && (isThirdMember() || isCompanyMember())">Aprovar todos</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-if="requestId !== 'new'">
            <ok-input :model="window_range" @updated="(x) => {
              window_range = x;
            }
              " :label="'Janela de tolerância (h)'" :placeholder="' '" :id="'window_range'" :name="'window_range'"
              :rules="'integer'">
            </ok-input>
          </div>
          <div class="col-md-8" v-if="requestId !== 'new'"></div>
          <div class="col-md-4">
            <ok-input :model="request.idthird" @updated="(x) => {
              request.idthird = x;
            }
              " :label="'Empresa Terceira'" :options="thirdCompanyList" :placeholder="'Selecione o terceiro'"
              :id="'request-third'" :name="'idthird'" @selected="selectedThird()" style="margin-top: 4px;"
              :disabled="thirdCompanyList.length == 0 || isThird || isFieldsDisabled">
            </ok-input>
          </div>
          <div class="col-md-8">
            <!-- <ok-input :model="request.iddriver" @updated="(x) => {
              request.iddriver = x;
            }
              " :label="'Motorista'" :options="driverList" :placeholder="'Selecione o motorista'"
              :id="'request-driver'" :name="'iddriver'" @selected="loadDriverInfos()" @onInput="teste()" style="margin-top: 4px;"
              :disabled="isFieldsDisabled">
            </ok-input> -->
            <div class="select-with-label">
              <v-select label="name" v-model="request.iddriver" :options="driverList" :reduce="(driver) => driver.value"
                placeholder="Digite o nome do motorista" @search="searchDriver"></v-select>
              <label>Motorista</label>
            </div>
          </div>
          <div class="col-md-4">
            <ok-input :model="request.idpark" @updated="(x) => {
              request.idpark = x;
            }
              " :label="'Parque'" :options="parkList" :placeholder="'Selecione o parque'" :id="'request-park'"
              :name="'idpark'" style="margin-top: 4px;" :disabled="parkList.length == 0 || isFieldsDisabled"
              @selected="loadCompanyList()">
            </ok-input>
          </div>
          <div class="col-md-4">
            <ok-input :model="request.idcompany" @updated="(x) => {
              request.idcompany = x;
            }
              " :label="'Locatário'" :options="companyList" :placeholder="'Selecione a empresa'"
              :id="'request-company'" :name="'idcompany'" style="margin-top: 4px;"
              :disabled="companyList.length == 0 || isFieldsDisabled">
            </ok-input>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <ok-input :model="request.idvehicle" @updated="(x) => {
              request.idvehicle = x;
            }
              " :label="'Veículo'" :options="vehicleList" :placeholder="'Selecione o veículo'" :id="'request-vehicle'"
              :name="'idvehicle'" style="margin-top: 4px;" @selected="checkPlates()" :disabled="vehicleList.length == 0 || isFieldsDisabled">
            </ok-input>
          </div>
          <div class="col-md-4">
            <ok-input :model="request.plate2" @updated="(x) => {
              request.plate2 = x;
            }
              " :label="'Placa 2'" :placeholder="' '" :id="'plate2'" :name="'plate2'" style="margin-top: 4px;"
              :disabled="isPlate2Disabled || isFieldsDisabled">
            </ok-input>
          </div>
          <div class="col-md-4">
            <ok-input :model="request.plate3" @updated="(x) => {
              request.plate3 = x;
            }
              " :label="'Placa 3'" :placeholder="' '" :id="'plate3'" :name="'plate3'" style="margin-top: 4px;"
              :disabled="isPlate3Disabled || isFieldsDisabled">
            </ok-input>
          </div>
        </div>

        <hr class="separator">

        <div class="row" style="padding: 10px 0px;">
          <div class="col-md-3">
            <date-picker v-model="selectedDates" value-type="YYYY-MM-DD" format="DD/MM/YYYY" type="date" :inline="true"
              placeholder="Selecione a data" :lang="daysAndMonths" @input="handleDateSelection" :multiple="true"
              :disabled-date="disabledBeforeToday">
            </date-picker>
          </div>
          <div class="col-md-9">
            <h2 class="title-sm" style="margin-bottom: 15px;">Horário</h2>
            <div v-for="(date, index) of selectedDatesAndHours" :key="date.day">
              <div class="hour-container">
                <ok-input :model="date.day" @updated="(x) => {
                  date.day = x;
                }
                  " :label="'Data'" :placeholder="' '" :id="'day'" :name="'day'" :disabled="true"
                  style="margin-top: 4px; margin-bottom: 0px;">
                </ok-input>
                <ok-input :model="date.hour" @updated="(x) => {
                  date.hour = x;
                }
                  " :label="'Horário'" :placeholder="' '" :id="'hour'" :name="'hour'"
                  :disabled="date.idrequest !== undefined" :mask="'##:##'" rules="required|hourFormat"
                  style="margin-top: 4px; margin-bottom: 0px;">
                </ok-input>
                <ok-input :model="date.company_code" v-if="request?.Company?.company_code_enable || checkCompanyCode(request.idcompany)" @updated="(x) => {
                  date.company_code = x;
                }
                  " :label="checkCompanyCode(request.idcompany) || 'Número do cliente'" :placeholder="' '" :id="'client_number'" :name="'client_number'"
                  :disabled="date.idrequest !== undefined"
                  style="margin-top: 4px; margin-bottom: 0px;">
                </ok-input>
                <button class="clean remove-date" style="font-size: 20px;" @click="removeDate(index)"
                  v-if="!date.idrequest">
                  <i class="fas fa-times"></i>
                </button>
                <button class="clean thumbs" v-else
                  :class="{ 'thumbs-approved': date.status === 2, 'thumbs-denied': date.status === -1 }"
                  style="font-size: 20px;" @click="goToRequest(date.idrequest)"
                  :disabled="date.status === 0">
                  <i class="fa-regular fa-thumbs-up"></i>
                </button>
              </div>
              <div class="hour-container-separator" v-if="index !== selectedDatesAndHours.length - 1"></div>
            </div>
            <div style="margin-top: 15px;" v-if="request && selectedDatesAndHours.length === 0">
              Selecione uma data no calendário
            </div>
          </div>
        </div>

        <hr class="separator">

        <div class="row">
          <div class="col-md-12" style="margin-top: 12px; margin-bottom: 18px;">
            <h2 class="title-sm">Acompanhantes</h2>
          </div>
          <div class="col-md-8" style="margin-bottom: 12px;" v-if="requestId === 'new'">
            <div class="select-with-label">
              <v-select label="name" v-model="selectedCompanion" :options="companionListMapped"
                :reduce="(order) => order.value" placeholder="Nome do acompanhante" @input="addSelectedCompanion"
                :class="{ 'disable-select ': companionListMapped.length == 0 }"></v-select>
              <label>Acompanhante</label>
            </div>
          </div>
          <div class="col-md-12" v-for="companion in request.Companions" :key="companion.idcompanion">
            <companion-item :companion="companion" :showRemove="true" @companionRemove="removeCompanion"></companion-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="request.Companions && request.Companions == 0">
              <p>Nenhum acompanhante adicionado</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import driverMixin from "../mixins/driver.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import vehicleMixin from "../mixins/vehicle.mixin.js";
import companionMixin from "../mixins/companion.mixin.js";
import requestMixin from "../mixins/request.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import CompanionItem from '@/components/CompanionItem.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [authMixin, thirdCompanyMixin, parkMixin, driverMixin, companyMixin, vehicleMixin, companionMixin, requestMixin],
  components: {
    MainLayout,
    OkInput,
    CompanionItem,
  },
  data() {
    return {
      // request: {
      //   iddriver: null,
      //   idpark: null,
      //   idvehicle: null,
      //   idcompany: null,
      //   idthird: null,
      //   companions: [],
      //   dates: [],
      // },
      window_range: 0,
      selectedCompanion: null,
      driverList: [],
      parkList: [],
      companyList: [],
      vehicleList: [],
      thirdCompanyList: [],
      companionList: [],
      companionListMapped: [],
      currentUser: null,
      isThird: false,
      plate2Vehicles: ["CARRETA", "TRUCK", "BITREM", "TOCO", "VUC"],
      selectedDates: [],
      selectedDatesAndHours: [],
      requestId: '',
      isFieldsDisabled: false,
      lastDriverList: null,
      isPlate2Disabled: true,
      isPlate3Disabled: true,
    };
  },
  watch: {
    'request.name': function (newValue, oldValue) {
      console.log('Novo nome:', newValue);
      console.log('Nome antigo:', oldValue);

    }
  },
  mounted() {
    this.currentUser = getLocalStorage('user')
    if (this.currentUser.job === '40' || this.currentUser.job === '41') this.isThird = true
    this.requestId = this.$route.params.id;
    if (this.requestId !== 'new') {
      this.loadPackage(this.requestId)
      this.isFieldsDisabled = true
    } else {
      this.listDrivers()
    }
    this.listThirdCompanies()
    this.listParks()
    this.listVehicles()
  },
  methods: {
    searchDriver(text) {
      const currentDate = new Date
      if (text.length < 4 || (this.lastDriverList && currentDate.getTime() - this.lastDriverList.getTime() <= 3000)) return
      this.getDriverList(text)
    },
    goBackWindow() {
      this.goBack(window)
    },
    loadPackage(id) {
      let self = this;
      this.getMultipleRequestMixin(id).then((response) => {
        self.selectedDatesAndHours = response.package.Requests.map(item => {
          self.selectedDates.push(item.day.slice(0, 10))
          const obj = {
            idrequest: item.idrequest,
            status: item.status,
            day: this.formatDateToFront(item.day.slice(0, 10)),
            hour: item.hour,
            company_code: item.company_code
          }
          return obj
        })
        self.request = response.package.Requests[0]
        if (self.requestId !== 'new') {
          const driver = self.request.Driver
          self.driverList.push({
            name: `${driver.name} ${driver.lastname} - CPF: ${self.CPFMask(driver.cpf)}`,
            value: driver.iddriver
          })
        }
        self.checkPlates()
        self.window_range = response.package.window_range
      })
    },
    selectedThird() {
      if (this.requestId === 'new') {
        this.listDrivers()
        delete this.request.iddriver
      }
    },
    listDrivers() {
      let self = this;
      const id = this.request.idthird ? this.request.idthird : self.currentUser?.Company?.idcompany
      if(!id) return
      this.listDriverThirdCompanyMixin(id).then((response) => {
        self.driverList = response.third.driverList.map(item => {
          const obj = {
            name: `${item.name} ${item.lastname} - CPF: ${self.CPFMask(item.cpf)}`,
            value: item.iddriver
          }
          return obj
        })
      });
    },
    getDriverList(text) {
      let self = this;
      const currentDate = new Date
      if(!self.lastDriverList || currentDate.getTime() - self.lastDriverList.getTime() >= 3000) self.lastDriverList = new Date
      this.listDriversMixin(500, null, text).then((response) => {
        self.driverList = response.driverList.map(item => {
          const obj = {
            name: `${item.name} ${item.lastname} - CPF: ${self.CPFMask(item.cpf)}`,
            value: item.iddriver
          }
          return obj
        })
      });
    },
    loadDriverInfos() {
      if (this.requestId === 'new') {
        this.request.Companions = []
        this.request.idvehicle = null
      }
      this.listCompanions()
    },
    loadCompanyList() {
      if (this.requestId === 'new') this.request.idcompany = null
      this.listCompanies()
    },
    listParks() {
      let self = this;
      this.listParksMixin(500).then((response) => {
        self.parkList = response.parkList.map(item => {
          const obj = {
            name: item.name,
            value: item.idpark
          }
          return obj
        })
      });
    },
    listCompanies() {
      let self = this;
      this.listCompaniesMixin(500, null, null, null, null, null, self.request.idpark).then((response) => {
        self.companyList = response.companyList.map(item => {
          const obj = {
            name: item.name,
            value: item.idcompany,
            company_code_enable: item.company_code_enable,
            company_code_name: item.company_code_name
          }
          console.log(obj)
          return obj
        })
      });
    },
    listVehicles() {
      let self = this;
      self.vehicleList = []
      this.listVehiclesMixin(null, null, null, null, null, null).then((response) => {
        self.vehicleList = response.vehicleList.map(item => {
          const obj = {
            name: `${item.plate} - ${item.type ? item.type : ''} ${item.year ? item.year : ''}`,
            value: item.idvehicle,
            type: item.type
          }
          return obj
        })
      });
    },
    listThirdCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500).then((response) => {
        self.thirdCompanyList = response.thirdList.map(third => {
          const obj = {
            name: third.name,
            value: third.idcompany
          }

          return obj
        })
        if (self.isThird) self.request.idthird = self.currentUser.Company.idcompany
      });
    },
    listCompanions() {
      let self = this;
      this.listCompanionsMixin(null, null, null, null, null, null, self.request.iddriver).then((response) => {
        self.companionList = response.companionList
        self.companionListMapped = response.companionList.map(item => {
          const obj = {
            name: `${item.name} ${item.lastname ? item.lastname : ''}`,
            value: item.idcompanion
          }
          return obj
        })
      })
    },
    addSelectedCompanion() {
      if (!this.selectedCompanion) return
      const companionIndex = this.companionList.findIndex((obj) => obj.idcompanion == this.selectedCompanion)
      const companionMappedIndex = this.companionListMapped.findIndex((obj) => obj.value == this.selectedCompanion)

      if (companionIndex !== -1) {
        const companion = this.companionList.splice(companionIndex, 1)[0]
        this.request.Companions.push(companion)
      }

      if (companionMappedIndex !== -1) this.companionListMapped.splice(companionMappedIndex, 1)

      this.selectedCompanion = null
    },
    removeCompanion(companionToRemove) {
      const index = this.request.Companions.findIndex(objeto => objeto.idcompanion === companionToRemove.idcompanion)
      if (index !== -1) this.request.Companions.splice(index, 1)
      this.companionList.push(companionToRemove)
      this.companionListMapped.push({ name: `${companionToRemove.name} ${companionToRemove.lastname}`, value: companionToRemove.idcompanion })
    },
    saveRequest() {
      let self = this;
      self.cleanAditionalPlates()
      let requestCopy = JSON.parse(JSON.stringify(self.request))
      let requests = []
      this.selectedDatesAndHours.forEach(element => {
        requests.push({
          ...requestCopy,
          day: self.formatDateToServer(element.day),
          hour: element.hour,
          idrequest: element.idrequest,
          entry_type: requestCopy.idvehicle ? 2 : 1,
          company_code: element.company_code
        })
      });
      const obj = {
        window_range: parseInt(this.window_range),
        request: requests
      }
      this.saveRequestMultipleMixin(obj, this.requestId).then((response) => {
        self.selectedDatesAndHours = response.package.Requests.map(item => {
          self.selectedDates.push(item.day.slice(0, 10))
          const obj = {
            idrequest: item.idrequest,
            status: item.status,
            day: item.day.slice(0, 10),
            hour: item.hour,
            company_code: item.company_code
          }
          return obj
        })
        self.request = response.package.Requests[0]
      })
    },
    approveAll() {
      let self = this;
      this.approveMultipleRequestMixin(this.request.idpackage).then((response) => {
        self.selectedDatesAndHours = response.package.Requests.map(item => {
          self.selectedDates.push(item.day.slice(0, 10))
          const obj = {
            idrequest: item.idrequest,
            status: item.status,
            day: item.day.slice(0, 10),
            hour: item.hour,
            company_code: item.company_code
          }
          return obj
        })
        self.request = response.package.Requests[0]
      })
    },
    denyAll() {
      let self = this;
      this.denyMultipleRequestMixin(this.request.idpackage).then((response) => {
        self.selectedDatesAndHours = response.package.Requests.map(item => {
          self.selectedDates.push(item.day.slice(0, 10))
          const obj = {
            idrequest: item.idrequest,
            status: item.status,
            day: item.day.slice(0, 10),
            hour: item.hour,
            company_code: item.company_code
          }
          return obj
        })
        self.request = response.package.Requests[0]
      })
    },
    checkPlates() {
      const vehicle = this.vehicleList.find(obj => obj.value === this.request.idvehicle)
      this.setPlatesDisable(true, true)
      if (!vehicle) return
      if (this.plate2Vehicles.includes(vehicle.type)) this.setPlatesDisable(false, true)
      if (vehicle.type === 'BITREM') this.setPlatesDisable(false, false)
    },
    setPlatesDisable(plate2, plate3) {
      this.isPlate2Disabled = plate2
      this.isPlate3Disabled = plate3
    },
    cleanAditionalPlates() {
      if(this.isPlate2Disabled) this.request.plate2 = null
      if(this.isPlate3Disabled) this.request.plate3 = null
    },
    handleDateSelection() {
      if(!this.request.idpark) {
        this.$toasted.info('Selecione um parque')
        this.selectedDates.pop()
        return
      }
      if (!this.request.idcompany) {
        this.$toasted.info('Selecione um locatário')
        this.selectedDates.pop()
        return
      }

      if (this.selectedDates.length < this.selectedDatesAndHours.length) {
        console.log(this.selectedDatesAndHours)
        let itemsToRemove = []
        for (const [index, item] of this.selectedDatesAndHours.entries()) {
          if (!this.selectedDates.includes(this.formatDateToServer(item.day))) {
            itemsToRemove.push({ item, index })
          }
        }
        console.log(itemsToRemove)
        const reversed = itemsToRemove.reverse()
        reversed.forEach(obj => {
          if (obj.item.idrequest) {
            this.selectedDates.push(this.formatDateToServer(obj.item.day))
            this.$toasted.info('Data tem uma solicitação criada e não pode ser desselecionado')
          }
          else this.selectedDatesAndHours.splice(obj.index, 1)
        })
      } else {
        console.log(this.selectedDates)
        const obj = {
          day: this.formatDateToFront(this.selectedDates.at(-1)),
          hour: this.getHour(this.selectedDates.at(-1))

        }

        this.selectedDatesAndHours.push(obj)
      }
    },
    getHour(day) {
      const founded = this.selectedDatesAndHours.find(item => this.formatDateToServer(item.day) === day)
      if (founded) return founded.hour
      if (this.selectedDatesAndHours && this.selectedDatesAndHours.length > 0) return this.selectedDatesAndHours[0].hour
      return ''
    },
    removeDate(indexToRemove) {
      this.selectedDates.splice(indexToRemove, 1)
      this.selectedDatesAndHours.splice(indexToRemove, 1)
    },
    goToRequest(idrequest) {
      this.$router.push('/request-view/' + idrequest)
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    checkCompanyCode(companyId) {
      const company = this.companyList.find(obj => obj.value === companyId)
      if(company?.company_code_enable) return company.company_code_name
      return null
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#request-register {

  .action {
    width: 150px;
  }

  .mx-calendar {
    width: auto;
  }

  .hour-container{
    display: flex;
    gap: 12px;
    
    .remove-date {
      font-size: 20px;
      width: 50px;
      text-align: right;
    }
  }

  .thumbs {
    color: $gray-md;

    &:disabled{
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .thumbs-approved {
    color: $green;
  }

  .thumbs-denied {
    color: $red;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  
  .hour-container-separator{
    display: none;
  }
}

@media (max-width: 1200px) {
  #request-register{
    .hour-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .hour-container-separator{
      display: block;
      height: 1px;
      width: 100%;
      background-color: $gray-md;
      margin-block: 15px;
    }
  }
}

</style>