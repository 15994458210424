<template>
  <main-layout>
    <div class="content-container">
      <div id="request-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Solicitação</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space separator-bottom" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && request.idrequest == undefined">Voltar</button>
          </div>
        </div>
        <div class="row" v-if="request.Driver">
          <div class="col-md-4">
            <div class="data-box" style="padding: 18px 15px;">
              <div class="row">
                <div class="col-md-12 toggle-photo">
                  <ImageItem :path="request.Driver.image_photo" :isFull="true" v-if="photoType"></ImageItem>
                  <ImageItem :path="request.Driver.image_document" :isFull="true" v-else></ImageItem>
                </div>
                <div class="col-md-12 last separator-bottom toggle-photo" style="margin-top: 6px;">
                  <button class="left toggle-btn" v-bind:class="{ 'active': photoType }" @click="photoType = true">Foto</button>
                  <button class="right toggle-btn" v-bind:class="{ 'active': !photoType }" @click="photoType = false">CNH</button>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 style="margin-bottom: 10px">{{ request.Driver.name }} {{ request.Driver.lastname }}</h2>
                  <p>Telefone: {{ request.Driver.phone | formatPhone }}</p>
                  <p>E-mail: {{ request.Driver.email }}</p>
                  <p>CPF: {{ request.Driver.cpf | formatCpf }}</p>
                  <p>CNH: {{ request.Driver.cnh }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 class="highlight" v-if="request.Driver.company_name">{{ request.Driver.company_name }}</h2>
                  <hp v-else>Empresa: -</hp>
                  <p>CNPJ: {{ request.Driver.company_cnpj | formatCnpj | verifyIfNull }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h3 class="highlight">Outras placas</h3>
                  <p>Placa 2: {{ request.plate2 | verifyIfNull }}</p>
                  <p>Placa 3: {{ request.plate3 | verifyIfNull }}</p>
                </div>
                <button class="app-btn app-btn-outline center" style="margin-top:20px"
                  @click="openNewTab('/drivers/' + request.iddriver)">Ir para motorista</button>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <ValidationObserver ref="validator" class="row" v-if="tab == 'base'">
              <div class="col-md-6">
                <p style="font-weight: bold;" v-if="request.Company.company_code_enable">{{ request.Company.company_code_name || 'Número do cliente' }}: {{ request.company_code | verifyIfNull }}</p>
              </div>
              <div class="col-md-6 last">
                <p style="margin-left: 23.5px; line-height: 36px;">Status: <span class="highlight"
                    v-bind:class="userStatusClass(request.status)">{{ requestStatus(request.status) }}</span></p>
              </div>
              <div class="col-md-6" style="margin-top: 6px;">
                <ok-input :model="request.hour_min" @updated="(x) => {
                  request.hour_min = x;
                }
                  " @onBlur="addWindowRangeToHour(request.hour_min, request.Company.window_range)"
                  @onEnter="addWindowRangeToHour(request.hour_min, request.Company.window_range)"
                  :label="'Horário mínimo (hh:mm)'" :placeholder="' '" :id="'hour_min'" :name="'hour_min'" :mask="'##:##'"
                  rules="required|hourFormat">
                </ok-input>
              </div>
              <div class="col-md-6" style="margin-top: 6px;">
                <ok-input :model="request.hour_max" @updated="(x) => {
                  request.hour_max = x;
                }
                  " :label="'Horário máximo (hh:mm)'" :placeholder="' '" :id="'hour_max'" :name="'hour_max'"
                  :mask="'##:##'" rules="required|hourFormat">
                </ok-input>
              </div>
              <div class="col-md-12 last" style="margin-top: 6px;">
                <button class="app-btn app-btn-cancel"
                  @click="denyRequestMixin(request)" :disabled="request.status === 0">Negar</button>
                <button class="app-btn app-btn-primary left-space"
                  @click="approveRequestMixin(request)" :disabled="request.status === 0">Aprovar</button>
              </div>

              <div class="col-md-12">
                <h2 class="title-sm separator-title">Solicitação</h2>
                <request-details-item :request="request"></request-details-item>
              </div>

              <div class="col-md-12">
                <h2 class="title-sm separator-title">Veículo</h2>
                <vehicle-item :vehicle="request.Vehicle"></vehicle-item>
              </div>

              <div class="col-md-12">
                <h2 class="title-sm separator-title">Acompanhantes</h2>
                <div v-for="companion in request.Companions" :key="companion.idrequest">
                  <companion-item :companion="companion"></companion-item>
                </div>
                <div class="no-content" v-if="request.Companions && request.Companions == 0">
                  <p>Nenhum acompanhante para exibir</p>
                </div>
              </div>

              <div class="col-md-12">
                <h2 class="title-sm separator-title">Aprovador</h2>
                <user-item :userItem="request.User" :simple="true"></user-item>
                <div class="no-content" v-if="request.User == null">
                  <p>Indefinido</p>
                </div>
              </div>
              <div class="col-md-12">
                <h2 class="title-sm separator-title">Cadastro</h2>
                <registration-item :registrationItem="request.registration" :simple="true"></registration-item>
              </div>
            </ValidationObserver>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
// import moment from 'moment'
import requestMixin from "../mixins/request.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import RequestDetailsItem from '@/components/RequestDetailsItem.vue'
import RegistrationItem from '@/components/RegistrationItem.vue'
import VehicleItem from '@/components/VehicleItem.vue'
import CompanionItem from '@/components/CompanionItem.vue'
import UserItem from '@/components/UserItem.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [requestMixin],
  components: {
    MainLayout,
    OkInput,
    RequestDetailsItem,
    RegistrationItem,
    VehicleItem,
    CompanionItem,
    UserItem,
    ImageItem,
  },
  data() {
    return {
      request: {},
      tab: "base",
      tab2: "list",
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadRequest();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadRequest() {
      let self = this;
      const id = this.$route.params.id;
      this.getRequestMixin(id).then((response) => {
        self.request = response.request;
        if (self.request.status == 1) {
          // self.request.hour_min = self.request.hour;
          // let t = moment(self.request.hour_min, 'HH:mm').format();
          // if (self.request.Company.window_range) {
          //   self.request.hour_max = moment(String(t)).add(self.request.Company.window_range, 'hours').format('HH:mm');
          // }
        }
      });
    },
    changeTab(value) {
      if (value != 'parks') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
    // getBtnTitle(){
    //   if(this.disableApproveDeny(this.request)) return 'Não é permitido atualização após horário máximo'
    //   else return ''
    // }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#request-view {}

@media (max-width: 600px) {
  #request-view {
    .image-box-large {
      display: block;
    }
  }
}
</style>