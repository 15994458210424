<template>
  <main-layout>
    <div class="content-container">
      <div id="company-register" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Locatário</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteCompanyMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/companies/new') == -1 && isAdministrator() && !isThird">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && company.idcompany == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveCompanyMixin(company, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/companies/new') == -1 && isCompanyAdm() && tab == 'base' && !isThird">Salvar</button>
            <button class="app-btn app-btn-outline left-space" @click="saveCompanyMixin(company, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/companies/new') != -1 && isGlpMember() && !isThird">Registrar</button>
            <button class="app-btn app-btn-cancel left-space" @click="removeCompany()"
              v-if="isThird && company.CompanyThird.status === 1">Cancelar</button>
            <button class="app-btn app-btn-primary left-space" @click="approveCompany()"
              v-if="isThird && company.CompanyThird.status === 1">Aprovar</button>
            <button class="app-btn app-btn-cancel left-space" @click="removeCompany()"
              v-if="isThird && company.CompanyThird.status !== 1">Desligar</button>
          </div>
          <div class="col-md-10">
            <!-- <h2 style="line-height: 36px; margin-right: 10px">Dados do usuário</h2> -->
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                locatário</button>
              <!-- <button class="app-btn" @click="changeTab('address')" v-bind:class="{ 'app-btn-primary ': tab == 'address', 'app-btn-outline ': tab != 'address' }">Endereço</button> -->
              <button class="app-btn right-btn" @click="changeTab('parks')"
                v-bind:class="{ 'app-btn-primary ': tab == 'parks', 'app-btn-outline ': tab != 'parks' }"
                v-if="this.$route.fullPath.indexOf('/companies/new') == -1">Parques</button>
              <button class="app-btn left-btn" @click="changeTab('employees')"
                v-bind:class="{ 'app-btn-primary ': tab == 'employees', 'app-btn-outline ': tab != 'employees' }"
                v-if="this.$route.fullPath.indexOf('/companies/new') == -1 && !isThird">Empregados</button>
              <button class="app-btn right-btn" @click="changeTab('thirdCompany')"
                v-bind:class="{ 'app-btn-primary ': tab == 'thirdCompany', 'app-btn-outline ': tab != 'thirdCompany' }"
                v-if="this.$route.fullPath.indexOf('/companies/new') == -1 && !isThird">Terceiros</button>
            </div>
          </div>
        </div>
        <!-- <div class="row fix-right-margin" v-if="tab == 'base'"> -->
        <ValidationObserver ref="validator" class="row fix-right-margin" v-if="tab == 'base'">
          <div class="col-md-3 row">
            <div class="col-md-12">
              <!-- <image-upload v-model="company.logo"></image-upload> -->
              <image-upload v-model="company.logo" @uploaded="saveCompanyMixin(company, $route.params.id, true)"
                @deleted="saveCompanyMixin(company, $route.params.id, true)" v-if="isGlpMember()"></image-upload>
              <ImageItem :path="company.logo" v-else></ImageItem>
            </div>
          </div>
          <div class="col-md-9 row">
            <div class="col-md-6">
              <ok-input :model="company.cnpj" @updated="(x) => {
                company.cnpj = x;
              }
                " :label="'CNPJ'" :placeholder="' '" :id="'cnpj'" :name="'cnpj'" :mask="'##.###.###/####-##'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="company.name" @updated="(x) => {
                company.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="company.email" @updated="(x) => {
                company.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'email'" :name="'email'" rules="required|email">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="company.phone" @updated="(x) => {
                company.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'phone'" :name="'phone'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="company.window_range" @updated="(x) => {
                company.window_range = x;
              }
                " :label="'Janela de tolerância (h)'" :placeholder="' '" :id="'window_range'" :name="'window_range'"
                :rules="'integer'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item" style="margin-top: 8px;">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="company.status" :sync="true" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="company-code-container">
                <p class="company-code-title">Código interno para agendamentos</p>
                <div class="company-code-container-inner mt-2">
                  <div class="toggle-item">
                    <p>Habilitar</p>
                    <toggle-button class="toggle-btn" :value="false" v-model="company.company_code_enable " :sync="true" />
                  </div>
                  <ok-input :model="company.company_code_name" @updated="(x) => {
                    company.company_code_name = x;
                  }
                    " :label="'Nome do documento'" :placeholder="' '" :id="'company_code_name'" :name="'company_code_name'">
                  </ok-input>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <ok-input :model="company.resume" @updated="(x) => {
              company.resume = x;
            }
              " :label="'Descrição'" :placeholder="' '" :id="'resume'" :name="'resume'" :isTextArea="true">
            </ok-input>
          </div>
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h1 class="title-md">Endereço</h1>
          </div>
          <div class="col-md-12 row">
            <address-form :item="company"></address-form>
          </div>
          <!-- </div> -->
        </ValidationObserver>

        <div class="row fix-right-margin" v-if="tab == 'parks'">
          <div class="col-md-12 row" v-if="tab2 == 'list'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab2 = 'select'" v-if="isGlpMember()">Associar
                parque</button>
            </div>
            <div class="col-md-12" v-for="park in company.Parks" :key="'r' + park.idpark">
              <park-item :parkItem="park" :showRemove="isGlpMember()" :showGatezones="true" @done="loadCompany">
              </park-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="company.Parks && company.Parks.length == 0">
                Nenhum parque associado ao locatário
              </div>
            </div>
          </div>

          <div class="col-md-12 row" v-if="tab2 == 'select'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="tab2 = 'list'">Listar parques</button>
            </div>
            <div class="col-md-9" style="width: calc(100% - 175px);">
              <ok-input :model="filter.searchText" @updated="(x) => {
                filter.searchText = x;
              }
                " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="listParks">
              </ok-input>
            </div>
            <div class="col-md-3" style="width: 175px;">
              <button class="app-btn app-btn-text" style="margin-top: 9px" @click="cleanFilter()">Limpar
                resultados</button>
            </div>
            <div class="col-md-12" v-for="park in parksListFiltered(company.Parks)" :key="park.idpark">
              <park-item :parkItem="park" :showAdd="isGlpMember()" @done="loadCompany"></park-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="parkList && parkList.length == 0">
                Nenhum parque encontrado
              </div>
            </div>
          </div>
        </div>

        <div class="row fix-right-margin" v-if="tab == 'employees'">
          <div class="col-md-12 row" v-if="tab2 == 'list'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Funcionários</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab2 = 'register'" v-if="isCompanyAdm()">Adicionar
                funcionário</button>
            </div>
            <div class="col-md-12" v-for="user in company.Users" :key="user.iduser" @click="selectEmployee(user)">
              <employee-item :userItem="user"></employee-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="company.Users && company.Users.length == 0">
                Não foram adicionados funcionários a esta empresa. <br>
                Clique em 'Adicionar funcionário' para fazer esse relacionamento.
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12 row" v-if="tab2 == 'register'"> -->
          <ValidationObserver ref="validator" class="col-md-12 row" v-if="tab2 == 'register'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Funcionários</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="listEmployees()">Listar funcionários</button>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.name" @updated="(x) => {
                employee.name = x;
              }
                " :label="'Nome'" :placeholder="' '" :id="'employee_name'" :name="'employee_name'" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.lastname" @updated="(x) => {
                employee.lastname = x;
              }
                " :label="'Sobrenome'" :placeholder="' '" :id="'employee_lastname'" :name="'employee_lastname'"
                rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.email" @updated="(x) => {
                employee.email = x;
              }
                " :label="'E-mail'" :placeholder="' '" :id="'employee_email'" :name="'employee_email'"
                rules="required|email">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.phone" @updated="(x) => {
                employee.phone = x;
              }
                " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'employee_phone'"
                :name="'employee_phone'">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.birthday" @updated="(x) => {
                employee.birthday = x;
              }
                " :label="'Nascimento'" :placeholder="' '" :id="'employee_birthday'" :name="'employee_birthday'"
                :mask="'##/##/####'" :isDate="true">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.gender" @updated="(x) => {
                employee.gender = x;
              }
                " :label="'Sexo'" :options="genderOptions" :placeholder="' '" :id="'employee_gender'"
                :name="'employee_gender'" style="margin-top: 4px;">
              </ok-input>
            </div>
            <div class="col-md-6">
              <ok-input :model="employee.job" @updated="(x) => {
                employee.job = x;
              }
                " :label="'Função'" :options="employeeJobOptions" :placeholder="' '" :id="'employee_job'"
                :name="'employee_job'" style="margin-top: 4px;" rules="required">
              </ok-input>
            </div>
            <div class="col-md-6">
              <div class="toggle-item" style="margin-top: 8px;">
                <p>Status</p>
                <toggle-button class="toggle-btn" :value="false" v-model="employee.status" :sync="true" />
              </div>
            </div>
            <div class="col-md-12 last">
              <button class="app-btn app-btn-secondary" v-if="employee.iduser" @click="forgotPassword()">Reenviar email
                de alteração de senha</button>
            </div>
            <div class="col-md-12 last" style="margin-top: 18px">
              <button class="app-btn app-btn-cancel" v-if="employee.iduser && currentUser.job === '20' || this.isAdministrator()"
                @click="deleteEmployee()">Remover</button>
              <button class="app-btn app-btn-secondary left-space" v-if="employee.iduser && currentUser.job === '20' || this.isAdministrator()"
                @click="updateEmployee()">Atualizar</button>
              <button class="app-btn app-btn-secondary" v-if="!employee.iduser && currentUser.job === '20' || this.isAdministrator()"
                @click="addEmployee()">Salvar</button>
            </div>
            <!-- </div> -->
          </ValidationObserver>
        </div>

        <div class="row fix-right-margin" v-if="tab == 'thirdCompany'">
          <div class="col-md-12 row" v-if="tab3 == 'list'">
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab3 = 'select'" v-if="isCompanyAdm()">Associar
                terceiro</button>
            </div>
            <div class="col-md-12" v-for="thirdCompany in company.third" :key="'r' + thirdCompany.idcompany">
              <ThirdCompanyItem :thirdCompanyItem="thirdCompany" :showRemove="isCompanyAdm()" @done="loadCompany"
                :showActions="true">
              </ThirdCompanyItem>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="company.third && company.third.length == 0">
                Nenhum terceiro associado ao locatário
              </div>
            </div>
          </div>

          <div class="col-md-12 row" v-if="tab3 == 'select'">
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="tab3 = 'list'">Listar terceiros</button>
            </div>
            <div class="col-md-9" style="width: calc(100% - 175px);">
              <ok-input :model="filter.searchText" @updated="(x) => {
                filter.searchText = x;
              }
                " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'" @onEnter="listParks">
              </ok-input>
            </div>
            <div class="col-md-3" style="width: 175px;">
              <button class="app-btn app-btn-text" style="margin-top: 9px" @click="cleanFilter()">Limpar
                resultados</button>
            </div>
            <div class="col-md-12" v-for="thirdCompany in thirdCompaniesListFiltered(company.third, thirdCompanyList)"
              :key="thirdCompany.idcompany">
              <ThirdCompanyItem :thirdCompanyItem="thirdCompany" :showAdd="isCompanyAdm()" @done="loadCompany">
              </ThirdCompanyItem>
            </div>
            <div class="col-md-12">
              <div class="no-content"
                v-if="thirdCompanyList && thirdCompaniesListFiltered(company.third, thirdCompanyList).length == 0">
                Nenhum terceiro encontrado
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import userMixin from "../mixins/user.mixin.js";
import thirdCompanyMixin from "../mixins/thirdCompany.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import ParkItem from '@/components/ParkItem.vue'
import EmployeeItem from '@/components/EmployeeItem.vue'
import ThirdCompanyItem from '@/components/ThirdCompanyItem.vue'
import AddressForm from '@/components/AddressForm.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import { getLocalStorage } from "../js/encryption";
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [authMixin, companyMixin, parkMixin, userMixin, thirdCompanyMixin],
  components: {
    MainLayout,
    OkInput,
    ParkItem,
    EmployeeItem,
    ThirdCompanyItem,
    AddressForm,
    ImageUpload,
    ImageItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
      tab3: "list",
      employee: {
        job: '20'
      },
      parkList: [],
      thirdCompanyList: [],
      filter: {
        searchText: null,
      },
      currentUser: null,
      isThird: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.currentUser = getLocalStorage('user')
    if (this.currentUser.job === '40' || this.currentUser.job === '41') this.isThird = true
    if (id !== 'new') {
      this.loadCompany();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadCompany() {
      let self = this;
      const id = this.$route.params.id;
      if (this.isThird) {
        this.getCompanyThirdCompanyMixin(id, self.currentUser.idcompany).then((response) => {
          self.company = response.third.company;
        });
      } else {
        this.getCompanyMixin(id).then((response) => {
          self.company = response.company;
        });
      }
    },
    listParks() {
      let self = this;
      this.listParksMixin(500).then((response) => {
        self.parkList = response.parkList;
      });
    },
    listThirdCompanies() {
      let self = this;
      this.listThirdCompaniesMixin(500).then((response) => {
        self.thirdCompanyList = response.thirdList
      });
    },
    addEmployee() {
      let self = this;
      const id = this.$route.params.id;
      this.addEmployeeMixin(self.employee, id).then((response) => {
        console.log(response);
        if (response != null) {
          self.listEmployees();
          self.loadCompany();
          self.$toasted.show('Empregado adicionado com sucesso');
        }
      });
    },
    updateEmployee() {
      let self = this;
      this.updateEmployeeMixin(self.employee).then((response) => {
        console.log(response);
        self.listEmployees();
        self.loadCompany();
        self.$toasted.show('Empregado atualizado com sucesso');
      });
    },
    deleteEmployee() {
      let self = this;

      this.$swal.fire({
        title: 'Realmente deseja excluir este funcinário?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteEmployeeMixin(self.employee).then((response) => {
            console.log(response);
            self.listEmployees();
            self.loadCompany();
            self.$toasted.show('Empregado removido com sucesso');
          });
        }
      })
    },
    forgotPassword() {
      let self = this;
      this.forgotPasswordEmployeeMixin(self.employee.email);
    },
    changeTab(value) {
      this.tab2 = 'list'
      this.tab = value;
      this.employee = {};

      if (value === 'parks' && this.parkList.length == 0) this.listParks()
      if (value === 'thirdCompany' && this.thirdCompanyList.length == 0) this.listThirdCompanies()
    },
    listEmployees() {
      this.tab2 = 'list'
      this.employee = {};
    },
    selectEmployee(employee) {
      this.employee = JSON.parse(JSON.stringify(employee));
      this.employee.status = this.fixStatus(this.employee.status);
      this.tab2 = 'register'
    },
    approveCompany() {
      let self = this;
      const id = this.$route.params.id;

      this.approveDenyThirdCompanyMixin(id, self.currentUser.idcompany, { status: 2 }).then((response) => {
        self.$toasted.show(response.message);
        self.company.CompanyThird = response.data.company_third
      });
    },
    removeCompany() {
      let self = this
      const id = this.$route.params.id;
      this.deleteCompanyFromThirdCompanyMixin(id, self.currentUser.idcompany).then((response) => {
        console.log(response)
        self.$toasted.show('Locatário removido com sucesso.');
        self.goTo('/companies')
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#company-register {

.company-code-container{
  border: 1px solid $gray-md;
  border-radius: 8px;
  padding: 16px;

  .company-code-title{
      font-weight: 600;
    }

  .company-code-container-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    > * {
      flex: 1;
    }

    .toggle-item{
      margin-bottom: 12px;
      padding-inline: 0px;
    }
  }
}
}
</style>