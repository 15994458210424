<template>
  <main-layout>
    <div class="content-container">
      <div id="driver-confirmation-code" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Código de confirmação do motorista</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space" @click="goTo('/drivers')">Voltar</button>
          </div>
        </div>
        <div class="row fix-right-margin">
          <div class="row">
            <div class="col-md-12 search-with-btn" style="margin-top: 10px">
              <ok-input :model="number" @updated="(x) => {
                number = x;
              }
                " :label="'Número de telefone'" :placeholder="' '" :id="'number'" :name="'number'" @onEnter="handleSearch()">
              </ok-input>
              <button class="app-btn app-btn-primary" @click="handleSearch()">Buscar</button>
            </div>
            <div class="col-md-6 mt-3">
              <div class="confirmation-code-container" v-if="driverConfirmationCode">Código de confirmação: <span class="confirmation-code" @click="copyConfirmationCode">{{ driverConfirmationCode }}</span></div>
              <div class="highlight" v-if="driverConfirmationCode === ''">Nenhum código de confirmação encontrado</div>
            </div>
            <div class="col-md-6 mt-3 last" v-if="driver">
              Telefone cadastrado: <span class="highlight">{{ driver.rawPhone }}</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12" v-for="driver in driverList" :key="driver.iduser">
              <user-item :userItem="driver" :isDriver="true"></user-item>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import driverMixin from "../mixins/driver.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import UserItem from '@/components/UserItem.vue'

export default {
  mixins: [authMixin, driverMixin],
  components: {
    MainLayout,
    OkInput,
    UserItem,
  },
  data() {
    return {
      number: null,
      driverConfirmationCode: null,
      driverList: [],
      driver: null
    };
  },
  mounted() {
    if(!this.isAdministrator()) {
      this.$router.push('/drivers');
    }
  },
  methods: {
    handleSearch() {
      this.driverConfirmationCode = null;
      this.driverList = [];
      this.driver = null;

      const number = this.number ? this.number.replace(/\D/g, '') : null
      this.getConfirmationCode(number);
      this.listDrivers(number);
    },
    getConfirmationCode(number) {
      let self = this;
      this.getConfirmationCodeMixin(number).then((response) => {
        self.driverConfirmationCode = response.sms_code ? response.sms_code : '';
      });
    },
    listDrivers(number) {
      let self = this;

      this.listDriversRawMixin(null, null, number, null, null, null, null, null).then((response) => {
        self.driverList = response.driverList;
        
        if(self.driverList.length === 1) {
          self.driver = self.driverList[0];
        }
      });
    },
    copyConfirmationCode() {
      navigator.clipboard.writeText(this.driverConfirmationCode);
      this.$toasted.show('Código de confirmação copiado para a área de transferência');
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#driver-confirmation-code {
  .confirmation-code-container {
    font-size: 18px;

  .confirmation-code {
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: $primary;
      }
    }
  }
}
</style>